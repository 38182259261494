import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import CartIcon from '../cart-icon/cart-icon.component';
import WholesaleCustomerProfileIcon from '../wholesale-customer-profile-icon/wholesale-customer-profile-icon.component';
import logo from '../../assets/header_logo.png';
import businessLogo from '../../assets/middle_logo_cropped.png';

import { selectShopDetails } from '../../redux/shop/shop.selectors';
import { selectConfig } from '../../redux/config/config.selectors';
import { selectWholesaleUserDetails } from '../../redux/user/user.selectors';

import {
  HeaderContainer,
  InnerContainer,
  LogoContainer,
  Logo,
  OptionsContainer,
  BusinessNameContainer,
  BusinessName,
  BusinessNameLogo,
  WholesaleLoginButton,
  WholesaleLoginText,
} from './header.styles';

export const Header = ({ shopDetails, wholesaleUserDetails, config }) => {
  const { shopName } = shopDetails;
  const { enableWholesale } = config;
  return (
    <HeaderContainer>
      <InnerContainer>
        <LogoContainer to="/">
          <Logo src={logo} />
        </LogoContainer>
        <BusinessNameContainer>
          <BusinessName>{shopName}</BusinessName>
          {/* <BusinessNameLogo src={businessLogo} /> */}
        </BusinessNameContainer>
        <OptionsContainer>
          {enableWholesale && _.isEmpty(wholesaleUserDetails) && (
            <Link to="wholesaleLogin">
              <WholesaleLoginButton>
                <WholesaleLoginText>Wholesale Login</WholesaleLoginText>
              </WholesaleLoginButton>
            </Link>
          )}
          <CartIcon />
          {wholesaleUserDetails && <WholesaleCustomerProfileIcon />}
        </OptionsContainer>
      </InnerContainer>
    </HeaderContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  shopDetails: selectShopDetails,
  wholesaleUserDetails: selectWholesaleUserDetails,
  config: selectConfig,
});

export default connect(mapStateToProps)(Header);
