/*global google*/
// the above line tells eslint that google is a global name
// and is available at runtime
// https://github.com/hibiken/react-places-autocomplete/issues/150
// https://github.com/hibiken/react-places-autocomplete/issues/57#issuecomment-335043874

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import _ from 'lodash';
import { FaInfoCircle } from 'react-icons/fa';

import CheckoutItem from '../../components/checkout-item/checkout-item.component';
import PaymentDetails from '../../components/payment-details/payment-details.container';
import CheckoutCartDesktop from '../../components/checkout-cart-desktop/checkout-cart-desktop.component';
import Promotion from '../../components/promotion/promotion.container';
import SaveOrderSwitch from '../../components/save-order-switch/save-order-switch.component';

import { selectShopDetails } from '../../redux/shop/shop.selectors';
import { selectConfig } from '../../redux/config/config.selectors';

import {
  fetchOperatingHoursStart,
  setShowDeliveryZonesModal,
  setShowDynamicDeliveryFeeInfoModal,
} from '../../redux/shop/shop.actions';

import {
  selectCartItems,
  selectCartTotal,
  selectOrderType,
  selectDeliveryFee,
  selectDeliveryFeeDynamic,
  selectDeliveryFeePostcodes,
  selectBookingFee,
  selectPromotion,
  selectPromotionFee,
} from '../../redux/cart/cart.selectors';

import {
  setDeliveryFee,
  setDeliveryFeeDynamic,
  setDeliveryFeePostcodes,
  setBookingFee,
  setPromotion,
  setPromotionFee,
} from '../../redux/cart/cart.actions';

import {
  createUniqueIdentifier,
  calcHasFreeDelivery,
  calcBookingFee,
  calcFinalTotal,
  calcPromotionPercentType,
  calcCartTotal,
  calcCartSubtotalAndCartGst,
} from '../../redux/cart/cart.utils';

import {
  getDistanceFromLatLonInKm,
  calcDynamicFee,
} from '../../utils/cart-utils';

import {
  selectWholesaleUserDetails,
  selectUserCredentials,
} from '../../redux/user/user.selectors';

import { setUserCredentials as setReduxUserCredentials } from '../../redux/user/user.actions';

import {
  DELIVERY,
  PROMOTION_PERCENT_TYPE,
  PROMOTION_AMOUNT_TYPE,
  DELIVERY_CHECK_TYPE_RADIUS,
  DELIVERY_CHECK_TYPE_POSTCODES,
  DELIVERY_FEE_MODE_STATIC,
  DELIVERY_FEE_MODE_DYNAMIC,
} from '../../global.constants';

import {
  CheckoutPageContainer,
  CheckoutHeaderContainer,
  BackButtonContainer,
  BackContainer,
  BackIcon,
  BackText,
  CheckoutContentsContainer,
  HeaderBlockContainer,
  PriceHeader,
  AllFeesContainer,
  RHSContainer,
  Title,
  TitleContainer,
  InnerTitleContainer,
  OrderTypeContainer,
  OrderTypeText,
  CheckoutItemsContainer,
  TotalContainer,
  Container,
  PromotionContainer,
  COVIDContainer,
  COVIDHeading,
  COVIDLine,
  DeliveryInfoContainer,
  DeliveryInfoHeading,
  DeliveryInfoLine,
  BoldInline,
  Row,
  PromotionRow,
  PromotionFeeTitle,
  FeeTitleContainer,
  LeftSideContainer,
  FeePrice,
  FeeInfoContainer,
  OrderingInfoContainer,
  OrderingInfoHeading,
  OrderingInfoLine,
  TotalsContainer,
  ValueRowContainer,
  SubvalueTitle,
  SubvalueAmount,
  TotalValueRowContainer,
  TotalAmount,
  TotalTitle,
  FinalTotalAmount,
  FinalTotalTitle,
  MobileOnlyContainer,
  EmptyCartMsg,
} from './checkout.styles';

export const geocodeAddress = async (address, shopDetails) => {
  const {
    shopLatitude,
    shopLongitude,
    shopAddressSearchResultsRadius, // in metres
  } = shopDetails;

  const MAX_BOUND = shopAddressSearchResultsRadius / 1000.0;

  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        if (status !== 'OK') {
          // we still dont want to cause a 'throw' by calling 'reject'
          //
          // if geocoding fails, allow for fall-thru behaviour to
          // continue with payment processing. there are a few reasons
          // why status !== 'OK' should not get in the way of
          // submitting an order:
          //
          // geocoding may fail if (non-exhaustive list):
          // - QUERY_OVER_LIMIT: google account is over quota
          // - REQUEST_DENIED: web page is not allowed to use geocoder
          // - UNKOWN_ERROR: server error
          // - ERROR: request timeout for contacting google servers
          //
          // so to pass the conditional test, make 'distance' less than
          // MAX_BOUND: just subtract 1.0:
          resolve({ distance: MAX_BOUND - 1.0 });
          return;
        }

        if (_.size(results) >= 1) {
          const { address_components, geometry } = results[0];
          // postCodeComponent is used when delivery to address check
          // uses the postCodes feature
          const postCodeComponent = _.find(address_components, (comp) => {
            const { types } = comp;
            const postalCodeMaybe = _.find(types, (type) => {
              return type === 'postal_code';
            });
            return !!postalCodeMaybe;
          });

          const {
            location: { lat, lng },
          } = geometry;
          const deliveryLat = lat();
          const deliveryLng = lng();

          const distance = getDistanceFromLatLonInKm(
            deliveryLat,
            deliveryLng,
            shopLatitude,
            shopLongitude
          );

          resolve({ distance, postCodeComponent });
        } else {
          // TODO:
          // resolve or reject here??
          // check that this makes sense....
          // still accepting order, like above for statue !== 'OK'
          resolve({ distance: MAX_BOUND - 1.0 });
        }
      }
    );
  });
};

const getCorrectDeliveryFee = (
  deliveryFee,
  deliveryFeeDynamic,
  deliveryFeePostcodes,
  deliveryFeeCalculationType,
  deliveryToAddressCheckType
) => {
  // default delivery fee to use is for
  // 'radius' type, and 'static' delivery fee mode.
  // both deliveryFeeDynamic and deliveryFeePostcodes
  // are calculated only after the delivery address has been
  // typed in by user.
  let _deliveryFee = deliveryFee;

  if (
    deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS &&
    deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC
  ) {
    _deliveryFee = deliveryFeeDynamic;
  } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
    _deliveryFee = deliveryFeePostcodes;
  }

  return _deliveryFee;
};

export const CheckoutPage = ({
  cartItems,
  cartTotal,
  orderType,
  shopDetails,
  setDeliveryFee,
  setDeliveryFeeDynamic,
  setDeliveryFeePostcodes,
  deliveryFee,
  setBookingFee,
  bookingFee,
  promotion,
  setPromotion,
  promotionFee,
  setPromotionFee,
  config,
  fetchOperatingHoursStart,
  deliveryFeePostcodes,
  setShowDeliveryZonesModal,
  reduxUserCredentials,
  setReduxUserCredentials,
  deliveryFeeDynamic,
  setShowDynamicDeliveryFeeInfoModal,
}) => {
  const navigate = useNavigate();
  const {
    addBookingFee,
    bookingFeePercentage,
    deliveryFeeCalculationType,
    deliveryFeeDynamicModeCostFunction,
    deliveryToAddressCheckType,
    displayCovidInfo,
    displayPricingOnline,
    enablePromotionCodes,
    saveOrderToSavedOrders,
  } = config;

  const {
    deliveryFeeDynamicTypeMinimum,
    deliveryFeeDynamicTypeMaximum,
    deliverySurcharge,
    deliveryFreeThreshold,
    shopAddressSearchResultsRadius,
  } = shopDetails;
  const [totals, setTotals] = useState({
    cartSubtotal: 0.0,
    cartGst: 0.0,
    cartTotal: 0.0,
  });
  const [finalTotal, setFinalTotal] = useState(undefined);

  useEffect(() => {
    // get fresh operating hours. user may have parked on the shop screen
    // and admin may have changed operating hours
    fetchOperatingHoursStart();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const cartTotal = calcCartTotal(cartItems);
    const { cartSubtotal, cartGst } = calcCartSubtotalAndCartGst(cartItems);

    setTotals({
      cartSubtotal,
      cartGst,
      cartTotal,
    });
  }, [cartItems]);

  useEffect(() => {
    const doWork = async () => {
      if (orderType !== DELIVERY) return;

      if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS) {
        if (deliveryFeeCalculationType === DELIVERY_FEE_MODE_STATIC) {
          const fee = calcHasFreeDelivery(
            totals.cartTotal,
            deliverySurcharge,
            deliveryFreeThreshold
          )
            ? 0.0
            : deliverySurcharge;
          setDeliveryFee(fee);
        } else if (deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC) {
          // if cartTotal is already above free delivery threshold then
          // clearly have free delivery regardless of delivery distance
          if (totals.cartTotal >= deliveryFreeThreshold) {
            setDeliveryFeeDynamic(0.0);
          } else if (!reduxUserCredentials.address) {
            setDeliveryFeeDynamic(undefined);
          } else {
            // need to update dynamic delivery fee if cart total changes.
            // -> be aware of changes to fee when crossing free-delivery threshold
            const { distance } = await geocodeAddress(
              reduxUserCredentials.address,
              shopDetails
            );
            // have an address already so calc distance again
            const recalculatedFee = calcDynamicFee(
              distance,
              deliveryFeeDynamicModeCostFunction,
              deliveryFeeDynamicTypeMinimum,
              deliveryFeeDynamicTypeMaximum,
              shopAddressSearchResultsRadius
            );
            const finalFee = calcHasFreeDelivery(
              totals.cartTotal,
              recalculatedFee,
              deliveryFreeThreshold
            )
              ? 0.0
              : recalculatedFee;
            setDeliveryFeeDynamic(finalFee);
          }
        } else {
          // gutter
          // no-op
        }
      } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
        const fee = calcHasFreeDelivery(
          totals.cartTotal,
          deliveryFeePostcodes,
          deliveryFreeThreshold
        )
          ? 0.0
          : deliveryFeePostcodes;
        setDeliveryFeePostcodes(fee);
      } else {
        // default to static surcharge val
        const fee = calcHasFreeDelivery(
          totals.cartTotal,
          deliverySurcharge,
          deliveryFreeThreshold
        )
          ? 0.0
          : deliverySurcharge;
        setDeliveryFee(fee);
      }
    };
    doWork();
  }, [
    cartItems,
    totals.cartTotal,
    deliveryFreeThreshold,
    deliverySurcharge,
    deliveryFeePostcodes,
    deliveryFeeDynamic,
  ]);

  useEffect(() => {
    // 2. calc the promotion 'fee' (if applied)
    // it recalculates the value based on changes to cart, deliveryFee,
    // and whether promotion changes (and is applicable ie. if cartTotal is above minimum)
    if (_.isEmpty(promotion)) {
      setPromotionFee(undefined);
      return;
    }
    if (_.isEmpty(cartItems)) {
      setPromotionFee(undefined);
      setPromotion({});
      return;
    }
    if (!promotion.isPromoValid) {
      // note: not resetting the promotion in reducer
      // becuase it isPromoValid value is used to display
      // UI feedback to user about invalid promocode entered
      setPromotionFee(undefined);
      return;
    }
    if (cartTotal < promotion.promo.orderMinimum) {
      setPromotionFee(undefined);
      setPromotion({});
      return;
    }
    // can finally recalculate the promotion
    // (if promo depends on cartTotal eg. a percentage based promo)
    const {
      promo: { discountType, discountPercent, discountAmount },
    } = promotion;
    if (discountType === PROMOTION_PERCENT_TYPE) {
      const discount = calcPromotionPercentType(
        totals.cartTotal,
        discountPercent
      );
      setPromotionFee((100 * discount) / 100);
      return;
    } else if (discountType === PROMOTION_AMOUNT_TYPE) {
      const discount = -1.0 * discountAmount;
      setPromotionFee((100 * discount) / 100);
      return;
    } else {
      return;
    }
  }, [totals.cartTotal, promotion]);

  useEffect(() => {
    if (addBookingFee) {
      // 3. calc the booking fee (if feature is set for app)
      // it adds the cc processing charges after including
      // deliveryFee and promotionFee with cartTotal
      let bookingFee = 0.0;

      const _deliveryFee = getCorrectDeliveryFee(
        deliveryFee,
        deliveryFeeDynamic,
        deliveryFeePostcodes,
        deliveryFeeCalculationType,
        deliveryToAddressCheckType
      );

      bookingFee = calcBookingFee(
        totals.cartTotal,
        _deliveryFee,
        promotionFee,
        orderType,
        bookingFeePercentage
      );

      setBookingFee(bookingFee);
    }
  }, [
    totals.cartTotal,
    deliveryFee,
    deliveryFeePostcodes,
    deliveryFeeDynamic,
    promotionFee,
  ]);

  useEffect(() => {
    // 4. calc the final total to be charged
    let _total = 0.0;

    const _deliveryFee = getCorrectDeliveryFee(
      deliveryFee,
      deliveryFeeDynamic,
      deliveryFeePostcodes,
      deliveryFeeCalculationType,
      deliveryToAddressCheckType
    );

    _total = calcFinalTotal(
      totals.cartTotal,
      _deliveryFee /* look */,
      promotionFee,
      bookingFee,
      orderType,
      addBookingFee
    );

    setFinalTotal(_total);
  }, [
    totals.cartTotal,
    deliveryFee,
    deliveryFeePostcodes,
    deliveryFeeDynamic,
    promotionFee,
    bookingFee,
  ]);

  const handleDeliveryPostcodesFeeInfoClick = () => {
    setShowDeliveryZonesModal(true);
  };

  const handleDynamicDeliveryFeeInfoClick = () => {
    setShowDynamicDeliveryFeeInfoModal(true);
  };

  const calcDeliveryFeeUI = () => {
    let _inner = '';

    if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_POSTCODES) {
      _inner = (
        <Row>
          <LeftSideContainer>
            <FeeTitleContainer>Delivery Fee</FeeTitleContainer>
            <FeeInfoContainer onClick={handleDeliveryPostcodesFeeInfoClick}>
              <FaInfoCircle />
            </FeeInfoContainer>
          </LeftSideContainer>
          <FeePrice>
            {deliveryFeePostcodes ? deliveryFeePostcodes.toFixed(2) : '0.00'}
          </FeePrice>
        </Row>
      );
    } else if (deliveryToAddressCheckType === DELIVERY_CHECK_TYPE_RADIUS) {
      if (deliveryFeeCalculationType === DELIVERY_FEE_MODE_DYNAMIC) {
        _inner = (
          <Row>
            <LeftSideContainer>
              <FeeTitleContainer>Delivery Fee</FeeTitleContainer>
              <FeeInfoContainer onClick={handleDynamicDeliveryFeeInfoClick}>
                <FaInfoCircle />
              </FeeInfoContainer>
            </LeftSideContainer>
            <FeePrice>
              {deliveryFeeDynamic === undefined
                ? 'TBC'
                : deliveryFeeDynamic
                ? deliveryFeeDynamic.toFixed(2)
                : (0.0).toFixed(2)}
            </FeePrice>
          </Row>
        );
      } else {
        _inner = (
          <Row>
            <FeeTitleContainer>Delivery Fee</FeeTitleContainer>
            <FeePrice>{deliveryFee ? deliveryFee.toFixed(2) : 0.0}</FeePrice>
          </Row>
        );
      }
    } else {
      // default to radius static mode for delivery pricing
      _inner = (
        <Row>
          <FeeTitleContainer>Delivery Fee</FeeTitleContainer>
          <FeePrice>{deliveryFee ? deliveryFee.toFixed(2) : 0.0}</FeePrice>
        </Row>
      );
    }

    return _inner;
  };

  return (
    <CheckoutPageContainer>
      <BackButtonContainer>
        <BackContainer onClick={() => navigate('/')}>
          <BackIcon transform={'scale(1.0)'} />
          <BackText>Shop</BackText>
        </BackContainer>
      </BackButtonContainer>
      {displayCovidInfo && (
        <COVIDContainer>
          <COVIDHeading>COVID-19 INFORMATION</COVIDHeading>
          <COVIDLine>All of our deliveries are contactless.</COVIDLine>
          <COVIDLine>
            Please make sure that you are at home when delivery is expected. Our
            staff will leave them at the doorstep, ring the bell, and leave.
          </COVIDLine>
        </COVIDContainer>
      )}
      <DeliveryInfoContainer>
        <DeliveryInfoHeading>Meat Ordering Info</DeliveryInfoHeading>
        <DeliveryInfoLine>
          Our amazing meat suplier is
          <BoldInline>Enzo At Penny's</BoldInline>prime meats.
        </DeliveryInfoLine>
        <DeliveryInfoLine>
          Please place your meat orders at least
          <BoldInline>2 days in advance.</BoldInline>
        </DeliveryInfoLine>
      </DeliveryInfoContainer>
      {addBookingFee &&
        (bookingFeePercentage === 10 ||
          bookingFeePercentage === 15 ||
          bookingFeePercentage === 20) && (
          <OrderingInfoContainer>
            <OrderingInfoHeading>Public Holiday Info</OrderingInfoHeading>
            <OrderingInfoLine>
              {`* Today is a Public Holiday. Please note that a ${bookingFeePercentage}% service charge has been added to all orders.`}
            </OrderingInfoLine>
          </OrderingInfoContainer>
        )}
      <CheckoutContentsContainer>
        <TitleContainer>
          {orderType && (
            <InnerTitleContainer>
              <Title>Order Summary</Title>
              <OrderTypeContainer>
                <OrderTypeText>{_.capitalize(orderType)}</OrderTypeText>
              </OrderTypeContainer>
            </InnerTitleContainer>
          )}
        </TitleContainer>
        <CheckoutHeaderContainer>
          <HeaderBlockContainer>
            <span>Product</span>
          </HeaderBlockContainer>
          <HeaderBlockContainer>
            <span>Quantity</span>
          </HeaderBlockContainer>
          <HeaderBlockContainer>
            {displayPricingOnline && <PriceHeader>Price</PriceHeader>}
          </HeaderBlockContainer>
          <HeaderBlockContainer>
            <span>Remove</span>
          </HeaderBlockContainer>
        </CheckoutHeaderContainer>
        <CheckoutItemsContainer>
          {_.isEmpty(cartItems) ? (
            <EmptyCartMsg>Your cart is empty</EmptyCartMsg>
          ) : (
            <CheckoutItemsContainer>
              {cartItems.map((cartItem) => (
                <CheckoutItem
                  key={createUniqueIdentifier(cartItem)}
                  item={cartItem}
                />
              ))}
            </CheckoutItemsContainer>
          )}
        </CheckoutItemsContainer>
        {displayPricingOnline && (
          <AllFeesContainer>
            <TotalsContainer>
              <ValueRowContainer>
                <SubvalueTitle>Subtotal</SubvalueTitle>
                <SubvalueAmount>
                  {totals.cartSubtotal.toFixed(2)}
                </SubvalueAmount>
              </ValueRowContainer>
              <ValueRowContainer>
                <SubvalueTitle>GST *</SubvalueTitle>
                <SubvalueAmount>{totals.cartGst.toFixed(2)}</SubvalueAmount>
              </ValueRowContainer>
              <TotalValueRowContainer>
                <TotalTitle>Cart Total</TotalTitle>
                <TotalAmount>{totals.cartTotal.toFixed(2)}</TotalAmount>
              </TotalValueRowContainer>
            </TotalsContainer>
            <RHSContainer>
              {promotionFee !== undefined ? (
                <PromotionRow>
                  <PromotionFeeTitle>Promotion Applied</PromotionFeeTitle>
                  <FeePrice>{promotionFee.toFixed(2)}</FeePrice>
                </PromotionRow>
              ) : (
                <PromotionRow />
              )}
              {orderType === DELIVERY && calcDeliveryFeeUI()}
              {addBookingFee && bookingFee !== undefined && (
                <Row>
                  <FeeTitleContainer>Service Charge</FeeTitleContainer>
                  <FeePrice>{bookingFee.toFixed(2)}</FeePrice>
                </Row>
              )}
            </RHSContainer>
            <TotalContainer>
              <FinalTotalTitle>Total</FinalTotalTitle>
              <FinalTotalAmount>
                ${finalTotal === undefined ? 0.0 : finalTotal.toFixed(2)}
              </FinalTotalAmount>
            </TotalContainer>
          </AllFeesContainer>
        )}
      </CheckoutContentsContainer>
      <MobileOnlyContainer>
        {saveOrderToSavedOrders && <SaveOrderSwitch />}
      </MobileOnlyContainer>
      <Container>
        <PromotionContainer>
          {enablePromotionCodes && <Promotion />}
        </PromotionContainer>
        {reduxUserCredentials && (
          <PaymentDetails
            reduxUserCredentials={reduxUserCredentials}
            setReduxUserCredentials={setReduxUserCredentials}
          />
        )}
        <CheckoutCartDesktop config={config} />
      </Container>
    </CheckoutPageContainer>
  );
};

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
  cartTotal: selectCartTotal,
  orderType: selectOrderType,
  shopDetails: selectShopDetails,
  deliveryFee: selectDeliveryFee,
  deliveryFeeDynamic: selectDeliveryFeeDynamic,
  deliveryFeePostcodes: selectDeliveryFeePostcodes,
  bookingFee: selectBookingFee,
  promotion: selectPromotion,
  promotionFee: selectPromotionFee,
  config: selectConfig,
  wholesaleUserDetails: selectWholesaleUserDetails,
  reduxUserCredentials: selectUserCredentials,
});

const mapDispatchToProps = (dispatch) => ({
  setDeliveryFee: (deliveryFee) => dispatch(setDeliveryFee(deliveryFee)),
  setBookingFee: (bookingFee) => dispatch(setBookingFee(bookingFee)),
  setPromotion: (promotion) => dispatch(setPromotion(promotion)),
  setPromotionFee: (promotionFee) => dispatch(setPromotionFee(promotionFee)),
  fetchOperatingHoursStart: () => dispatch(fetchOperatingHoursStart()),
  setShowDeliveryZonesModal: (show) =>
    dispatch(setShowDeliveryZonesModal(show)),
  setReduxUserCredentials: (userCredentials) =>
    dispatch(setReduxUserCredentials(userCredentials)),
  setDeliveryFeePostcodes: (deliveryFeePostcodes) =>
    dispatch(setDeliveryFeePostcodes(deliveryFeePostcodes)),
  setDeliveryFeeDynamic: (deliveryFeeDynamic) =>
    dispatch(setDeliveryFeeDynamic(deliveryFeeDynamic)),
  setShowDynamicDeliveryFeeInfoModal: (show) =>
    dispatch(setShowDynamicDeliveryFeeInfoModal(show)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
