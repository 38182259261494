import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import OrderMethod from './order-method.component';

import {
  setOrderType,
  setDeliveryFee,
  setPromotionFee,
  setPromotion,
} from '../../redux/cart/cart.actions';

import { selectOrderType } from '../../redux/cart/cart.selectors';
import { selectShopDetails } from '../../redux/shop/shop.selectors';
import { selectConfig } from '../../redux/config/config.selectors';
import { selectWholesaleUserDetails } from '../../redux/user/user.selectors';

export const OrderMethodContainer = ({
  orderType,
  setOrderType,
  setDeliveryFee,
  shopDetails,
  setPromotionFee,
  setPromotion,
  config,
  wholesaleUserDetails,
}) => {
  const { shopAddressSearchResultsRadius } = shopDetails;

  const handleOrderTypeClick = (orderType) => {
    setOrderType(orderType);
    // it's a good idea to reset the promotion also.
    // if it is set and user switches back to shop from checkout,
    // then choses a different orderType, then the promotion may not
    // be valid for the new orderType
    setPromotionFee(undefined);
    setPromotion({});
  };

  return (
    <OrderMethod
      orderType={orderType}
      handleOrderTypeClick={handleOrderTypeClick}
      shopAddressSearchResultsRadius={shopAddressSearchResultsRadius}
      config={config}
      wholesaleUserDetails={wholesaleUserDetails}
    />
  );
};

const mapStateToProps = createStructuredSelector({
  orderType: selectOrderType,
  shopDetails: selectShopDetails,
  config: selectConfig,
  wholesaleUserDetails: selectWholesaleUserDetails,
});

const mapDispatchToProps = (dispatch) => ({
  setOrderType: (orderType) => dispatch(setOrderType(orderType)),
  setDeliveryFee: (deliveryFee) => dispatch(setDeliveryFee(deliveryFee)),
  setPromotionFee: (promotionFee) => dispatch(setPromotionFee(promotionFee)),
  setPromotion: (promotion) => dispatch(setPromotion(promotion)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderMethodContainer);
