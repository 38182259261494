import styled from 'styled-components';

import {
  white,
  black,
  mobileSplitPoint,
  veryLightGray,
  pink,
  collectionSplitPoint,
  green,
  darkGray,
  darkerGray,
} from '../../style-constants';

export const ShopPageContainer = styled.div`
  align-items: center;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 70px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin-top: 70px;
    padding-bottom: 100px;
    width: 100%;
  }
`;
ShopPageContainer.displayName = 'ShopPageContainer';

export const ContentContainer = styled.div`
  align-items: flex-start;
  background-color: ${white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 20px 20px 20px;
  width: 100%;
  z-index: 3;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: stretch;
    flex-direction: column;
    padding: unset;
    width: 100%;
  }
`;
ContentContainer.displayName = 'ContentContainer';

export const InnerContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 200px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: stretch;
    flex-direction: column;
    margin-bottom: unset;
    padding: unset;
    width: 100%;
  }
`;
InnerContainer.displayName = 'InnerContainer';

export const ImageContainer = styled.div`
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: row;
  height: 485px;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
  }
`;
ImageContainer.displayName = 'ImageContainer';

export const BusinessName = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 20px;
  position: absolute;
  top: 500px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
  }
`;
BusinessName.displayName = 'BusinessName';

export const OrderingInformation = styled.div`
  align-items: flex-start;
  background-color: ${white};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px 10px 10px 20px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    background-color: ${white};
    border-radius: 0px;
    padding: 0px 20px 20px 20px;
  }
`;
OrderingInformation.displayName = 'OrderingInformation';

export const OrderingInformationText = styled.pre`
  color: ${black};
  font-family: 'Cabin', sans-serif;
  /* text-align: center; */
  margin: 5px 0px;
  padding: 0px;
  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    margin: 5px 0px;
    padding: 0px;
  }
`;
OrderingInformationText.displayName = 'OrderingInformationText';

export const BackgroundImage = styled.img`
  margin-top: 70px;
  min-height: 250px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    /* width: 90%; */
    min-height: unset;
  }
`;
BackgroundImage.displayName = 'BackgroundImage';

export const BackgroundImageDesktopContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 485px;
  justify-content: center;
  padding: 0px 20px 0px 20px;
  width: 50%;
`;
BackgroundImageDesktopContainer.displayName = 'BackgroundImageDesktopContainer';

export const BackgroundImageDesktop = styled.img`
  /* height: 360px; */
  width: 60%;
`;
BackgroundImageDesktop.displayName = 'BackgroundImageDesktop';

export const DesktopContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${mobileSplitPoint}) {
    display: none;
  }
`;
DesktopContainer.displayName = 'DekstopContainer';

export const MobileContainer = styled.div`
  display: none;

  @media screen and (max-width: ${mobileSplitPoint}) {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;
MobileContainer.displayName = 'MobileContainer';

export const MainFeed = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding-right: 20px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-right: unset;
  }
`;
MainFeed.displayName = 'MainFeed';

export const SpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    padding-top: 15px;
  }
`;
SpinnerContainer.displayName = 'SpinnerContainer';

export const DeliveryInfoContainer = styled.div`
  background-color: ${pink};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 15px;
  width: 98%;

  @media screen and (max-width: ${collectionSplitPoint}) {
    width: 98%;
  }

  @media screen and (max-width: ${mobileSplitPoint}) {
    border-radius: 0px;
    width: 100%;
  }
`;
DeliveryInfoContainer.displayName = 'DeliveryInfoContainer';

export const DeliveryInfoHeading = styled.div`
  font-size: 22px;
`;
DeliveryInfoHeading.displayName = 'DeliveryInfoHeading';

export const DeliveryInfoLine = styled.div`
  padding-top: 15px;
`;
DeliveryInfoLine.displayName = 'DeliveryInfoLine';

export const BoldInline = styled.span`
  font-weight: 900;
  border-bottom: 1px solid ${black};
  margin: 0px 6px;
`;
BoldInline.displayName = 'BoldInline';

export const SearchContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 50px;
  width: 100%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    justify-content: center;
    margin-bottom: unset;
  }
`;
SearchContainer.displayName = 'SearchContainer';

export const SearchContainerInner = styled.div`
  background-color: ${veryLightGray};
  border-radius: 10px;
  border-bottom: 2px solid ${darkGray};
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px 5px;
  width: 50%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    justify-content: flex-start;
    width: 80%;
  }
`;
SearchContainerInner.displayName = 'SearchContainerInner';

export const SearchIconContainer = styled.div`
  align-items: center;
  background-color: ${veryLightGray};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  /* height: 40px; */
  justify-content: center;
  /* min-width: 10%; */
  padding-left: 5px;
`;
SearchIconContainer.displayName = 'SearchIconContainer';

export const SearchInput = styled.input`
  background: none;
  background-color: ${veryLightGray};
  border: none;
  font-size: 18px;
  height: 40px;
  padding: 0px 10px;
  display: block;
  width: 80%;

  @media screen and (max-width: ${mobileSplitPoint}) {
    width: 60%;
  }

  &:focus {
    outline: none;
  }
`;
SearchInput.displayName = 'SearchInput';

export const BackgroundImageMobileContainer = styled.div`
  align-items: center;
  background-color: black;
  display: flex;
  flex-direction: row;
  /* height: 370px; */
  min-height: 400px;
  justify-content: center;
  width: 100%;
`;
BackgroundImageMobileContainer.displayName = 'BackgroundImageMobileContainer';
export const FeeInfoContainer = styled.div`
  align-items: center;
  background-color: ${green};
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  padding: 5px 10px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    justify-content: center;
    margin-right: unset;
    padding: 15px;
  }
`;
FeeInfoContainer.displayName = 'FeeInfoContainer';

export const FeeInfoText = styled.div`
  color: ${white};
  font-size: 16px;
  text-align: center;
  padding-right: 5px;

  @media screen and (max-width: ${mobileSplitPoint}) {
    font-size: 16px;
  }
`;
FeeInfoText.displayName = 'FeeInfoText';
