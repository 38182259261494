export default {
  apiKey: 'AIzaSyBR46JqehctFkpeH3yNXgBrro6MIad_F0U',
  authDomain: 'anglesea-fruitz.firebaseapp.com',
  databaseURL: 'https://anglesea-fruitz.firebaseio.com',
  projectId: 'anglesea-fruitz',
  storageBucket: 'anglesea-fruitz.appspot.com',
  messagingSenderId: '837374910553',
  appId: '1:837374910553:web:898c27fa0fc7ce9271d148',
  measurementId: 'G-QDHQKC07Q6',
};
